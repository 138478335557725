import { AurgumentedAnalysis, CareerTomorrow, Perform } from "../images/index";
export const solutionLinks = [
  {
    image: Perform,
    link: "https://webuat.entomo.co/solutions/everyday-performance/",
    text: "performance management",
  },
  {
    image: AurgumentedAnalysis,
    link: "https://webuat.entomo.co/solutions/everyday-insights/",
    text: "augmented analytics",
  },
  {
    image: CareerTomorrow,
    link: "https://webuat.entomo.co/solutions/careers-tomorrow/",
    text: "careers tomorrow",
  },
];
