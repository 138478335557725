import React from "react";
import Slider from "react-slick/lib/slider";
import PropTypes from "prop-types";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { PeopleSayCarouselLists } from "../constants/PeopleSayCarouselLists";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PeopleSayCarousel = ({ title, subTitle }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <BsChevronRight size="3em" />,
    prevArrow: <BsChevronLeft size="3em" />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="bg-[#0064bf] h-auto py-20">
      <div className="m-auto ">
        <div className="text-center text-[#ffda00] m-auto leading-tight mb-3 xl:text-2xl text-xl md:text-base xl:font-extrabold font-medium md:font-bold">
          {title}
        </div>
        <h2 className="text-center text-3xl font-bold text-[#ffffff] mb-6 opacity-80 xl:text-4xl xl:font-extrabold md:text-3xl md:font-extrabold">
          {subTitle}
        </h2>
      </div>

      <Slider {...settings}>
        {PeopleSayCarouselLists.map((PeopleSayCarouselList) => (
          <div key={PeopleSayCarouselList} className="p-4">
            <div className="py-20 px-12 leading-tight flex justify-center items-center bg-[#ffffff] rounded-2xl m-auto md:m-0 md:w-full h-[26vh] w-[50vw] xl:h-[29vh] md:h-[30vh] md:p-8 xl:p-8 lg:h-[25vh] 2xl:h-[28vh] 2xl:p-16">
              <div className="">
                <span className="text-[orange] bg-[orange] inline text-xl">{PeopleSayCarouselList.star}</span>
                <div className="text-base  md:text-base text-[#4d4d4d] lg:text-base leading-tight pt-8 xl:text-base text">
                  {PeopleSayCarouselList.text}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

PeopleSayCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};
export default PeopleSayCarousel;
