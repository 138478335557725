import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { BsArrowLeft } from "react-icons/bs";
import Layout from "../containers/global/layout";
import Seo from "../components/seo";
import EnterpriseOfTomorrow from "../containers/EnterpriseOfTomorrow";
import CarouselBrands from "../components/CarouselBrands";
import Container from "../utils/Container";
import Award from "../components/Award";
import SpringAward from "../components/SpringAward";
import Banner from "../containers/Banner";
import Button from "../components/Button";
import SolutionLink from "../components/SolutionLink";
import { solutionLinks } from "../constants/SolutionLinks";
import EvolvingEnterprises from "../containers/EvolvingEnterprises";
import BannerText from "../components/BannerText";

import {
  AwardBg,
  AwardMob,
  BadgeImg,
  SpringFive,
  SpringFour,
  SpringOne,
  SpringThree,
  SpringTwo,
  PromiseListLogoOne,
  PromiseListLogoThree,
  PromiseListLogoTwo,
} from "../images";
import BadgeSection from "../components/BadgeSection";
import TotalEcnomicImpact from "../containers/TotalEcnomicImpact";
import PeopleSayCarousel from "../components/PeopleSayCarousel";
import Testimonial from "../containers/Testimonial";
import { testimonialsList } from "../constants/TestimonialsList";
import PromiseList from "../containers/PromiseList";
import homepage from "../constants/entomo-promises-variants";
import HubspotForm from "../components/HubspotForm";
import Modal from "../components/modal";
import { globalBrands } from "../constants/global-home-page";

const HomePage = ({ data }) => {
  const homeBanner = data.homeBanner.nodes[0];
  const promiseListContentOne = data.promiseListContentOne.nodes[0];
  const promiseListContentTwo = data.promiseListContentTwo.nodes[0];
  const promiseListContentThree = data.promiseListContentThree.nodes[0];
  const evolvingEnterprises = data.evolvingEnterprises.nodes[0];
  const totalEconomic = data.totalEconomic.nodes;

  const [currentFormEconomicLink, setcurrentFormEconomicLink] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <Layout>
      <Seo title="Home" />
      <Modal
        contentClassName="w-full md:w-[66%] xl:w-[50%]"
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
          setcurrentFormEconomicLink(null);
          document.getElementsByTagName("body")[0].style.overflow = "scroll";
        }}
        modalTitle="Fill your details here to download"
        hbStyle={{ backgroundColor: "#ffda00", width: "80%", margin: "0 auto", alignItems: "center" }}
        // style={{ height: "50vh", width: "50vw" }}
        btnstyle={{ color: "darkgray", opacity: ".7", fontsize: "11px" }}
      >
        <div className=" bg-primary-yellow-dark w-4/5 mx-auto">
          <div className=" bg-white w-11/12 mx-auto h-[1px]"></div>
        </div>
        <HubspotForm
          portalId="1750400"
          formId="f5d6e574-8805-44d7-a619-147200264d9c"
          containerId="hubspot-home-form"
          onFormSubmit={
            currentFormEconomicLink
              ? () => {
                  window.open(currentFormEconomicLink);
                }
              : null
          }
        />
      </Modal>

      <Banner bgImg={homeBanner.desktopImage.gatsbyImageData} mobImg={homeBanner.mobileImage.gatsbyImageData}>
        <div className="absolute top-[24%] lg:left-16 mx-24 sm:mx-0 2xl:left-28 md:top-48 md:left-12 2xl:top-1/4 xl:top-1/4 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-left sm:text-left sm:w-1/2 -mt-20 md:-mt-40 xl:-mt-16 sm:-mt-6 w-11/12 sm:w-unset">
          <BannerText
            title={
              <h2 className="text-3xl lg:text-4xl font-bold sm:text-normal xl:text-[56px] w-3/5 sm:w-full xl:leading-[64px] md:text-3xl">
                modern performance management software
              </h2>
            }
            subTitle={
              <p className="text-xl xl:w-full text-[#4B4219] font-medium lg:text-3xl lg:w-72 md:w-56 xl:text-[32px] sm:text-lg  w-3/5 sm:w-full md:text-base ">
                built for the future of work
              </p>
            }
          />
          <Button
            link="https://entomo.co/contact/"
            text="let’s get started"
            style={{ backgroundColor: "#0075c9", color: "white", paddingLeft: "1rem", paddingRight: "1rem" }}
            sideImg={<BsArrowLeft className="ml-4" />}
          />
          <div className="mt-8 2xl:mt-[8rem] sm:flex sm:justify-between sm:w-0 flex flex-col w-1/3 justify-between items-center sm:flex-row md:flex-wrap md:flex-row md:w-full md:mt-8 xl:flex xl:mt-28 md:text-xs md:-ml-4 lg:-ml-0 xl:flex-nowrap ">
            {solutionLinks.map((solutionLink) => (
              <SolutionLink key={solutionLink} solutionLink={solutionLink} />
            ))}
          </div>
        </div>
      </Banner>

      <EnterpriseOfTomorrow />
      <div>
        <CarouselBrands
          title={
            <p>
              Powering <span className="text-bold">30 million+</span> users,<br></br> 1.4 million+ businesses, trusted
              by global marquee brands
            </p>
          }
          content={
            <p>
              entomo’s mobile apps are reviewed by over half a million users on Android & Apple App Stores<br></br>with
              an average rating of above 4.5. One of them is among the top 2% of the apps globally
            </p>
          }
          globalBrands={globalBrands}
        />
      </div>
      <PromiseList
        variant={homepage}
        title={
          <h2 className="m-auto pt-24 text-white text-xl text-center w-11/12 font-bold 2xl:py-24 2xl:text-2xl 2xl:w-2/3 xl:text-2xl xl:w-3/4">
            <span className="text-[#fcdb03]">enterprises of tomorrow </span>will need more than legacy performance
            management systems. with <span className="text-[#fcdb03]">entomo</span>, drive performance by aligning goals
            and nudging actions
          </h2>
        }
        promiseList={[
          {
            mainImage: promiseListContentOne.mainImg.gatsbyImageData,
            subImage: PromiseListLogoOne,
            title: "goals",
            desc: "engage and align",
            reversed: false,
            listItems: [
              "align strategy, operational plans and people goals",
              "create, assign, manage and review tasks",
              "drive collaboration and engagement within and across teams",
            ],
          },
          {
            mainImage: promiseListContentTwo.mainImg.gatsbyImageData,
            subImage: PromiseListLogoTwo,
            title: "nudges",
            desc: "empower with goal-oriented",
            reversed: true,
            listItems: [
              "empower with everyday insights contextual to goals",
              "deliver hyper personalized action nudges",
              "drive performance behaviour",
            ],
          },
          {
            mainImage: promiseListContentThree.mainImg.gatsbyImageData,
            subImage: PromiseListLogoThree,
            title: "growth",
            desc: "enable people",
            reversed: false,
            listItems: [
              "energise performance with personalised career growth recommendations",
              "Identify and bridge skill gaps",
              "facilitate talent growth",
            ],
          },
        ]}
      />
      <Award
        bgImg={AwardBg}
        mobImg={AwardMob}
        firstText="our platform has been named as the"
        secondText="‘Most valuable Technical Innovation in Response To Covid 19’"
        thirdText="An acknowledgement of the impact that our platform has had in UAE’s response to covid 19"
      />
      <Testimonial testimonialsList={testimonialsList} />
      <BadgeSection
        bgImg={BadgeImg}
        firstText="entomo recognized as a leader in G2.com’s
    Asia Pacific grid for"
        secondText="Performance Management"
        thirdText="G2.com is world’s top business software review and comparison website"
      />
      <SpringAward
        heading="voted world's best in spring 2021 G2 awards"
        textOne="#1 in Strategic Planning"
        textTwo="#3 in Career Management"
        textThree="#1 in Performance Management(Asia)"
        textFour="#6 in Objective & Key Results (OKR)"
        textFive="#5 Overall"
        aspireImg={SpringOne}
        highperfomerImg={SpringTwo}
        leaderImg={SpringThree}
        leadspringImg={SpringFour}
        usersImg={SpringFive}
      />
      <Container className="bg-primary-blue-dark">
        <PeopleSayCarousel title=" our customers love us! " subTitle="what people say on G2.com about entomo" />
      </Container>
      <div className="bg-[#fcdb03] lg:pt-8 lg:pb-20 flex flex-col py-12">
        <div className="lg:px-8 float-left 2xl:px-20">
          <div className="m-auto">
            <div className="text-left text-lg sm:text-4xl text-black lg:p-12 leading-normal px-12  pb-0 xl:text-3xl xl:leading-normal lg:w-1/2 lg:text-3xl lg:leading-normal">
              <strong>
                <p className="pb-4 xl:text-3xl text-xl md:text-2xl xl:font-bold font-medium">
                  The Total Economic Impact™ (TEI)
                  <br />
                  of the entomo platform
                </p>
              </strong>
              <p className="text-[#4b4219] sm:text-2xl text:base leading-normal lg:leading-normal xl:text-2xl text-base md:text-2xl font-normal">
                by Forrester Research
              </p>
            </div>
          </div>
        </div>
        <div className="sm:px-12 px-12 py-8 rounded-lg m-auto w-full gap-[2rem] flex-col md:flex-row flex md:w-full">
          {totalEconomic.map((economiclist) => {
            console.log(economiclist);
            return (
              <TotalEcnomicImpact
                title={economiclist.title}
                subTitle={economiclist.subTitle}
                linkText={economiclist.linkText}
                key={economiclist.id}
                image={economiclist.image.gatsbyImageData}
                onClick={async () => {
                  setcurrentFormEconomicLink(economiclist.document[0].file.url);
                  setIsModalOpen(true);
                  document.getElementsByTagName("body")[0].style.overflow = "hidden";
                }}
              />
            );
          })}
        </div>
      </div>
      <EvolvingEnterprises
        bgImg={evolvingEnterprises.desktopImage.gatsbyImageData}
        mobImg={evolvingEnterprises.mobileImage.gatsbyImageData}
        title="evolving enterprises of tomorrow"
        leftText={evolvingEnterprises.leftText}
        rightText={evolvingEnterprises.rightText}
        bottomText={evolvingEnterprises.bottomText}
      />
    </Layout>
  );
};
HomePage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default HomePage;
export const query = graphql`
  query {
    homeBanner: allContentfulBannerContent(filter: { heading: { eq: "Global Home Page Banner" } }) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    promiseListContentOne: allContentfulGlobalHomePromiseList(filter: { heading: { eq: "GlobalHomePromiseList-1" } }) {
      nodes {
        mainImg {
          gatsbyImageData
        }
        subImg {
          gatsbyImageData
        }
      }
    }
    promiseListContentTwo: allContentfulGlobalHomePromiseList(filter: { heading: { eq: "GlobalHomePromiseList-2" } }) {
      nodes {
        mainImg {
          gatsbyImageData
        }
        subImg {
          gatsbyImageData
        }
      }
    }
    promiseListContentThree: allContentfulGlobalHomePromiseList(
      filter: { heading: { eq: "GlobalHomePromiseList-3" } }
    ) {
      nodes {
        mainImg {
          gatsbyImageData
        }
        subImg {
          gatsbyImageData
        }
      }
    }

    evolvingEnterprises: allContentfulEvolvingEnterprises {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
        rightText
        leftText
        bottomText
      }
    }
    totalEconomic: allContentfulGlobalTotalEconomicImpact {
      nodes {
        document {
          file {
            url
          }
        }
        title
        subTitle
        linkText
        id
        image {
          gatsbyImageData
        }
      }
    }
  }
`;
