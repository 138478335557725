import React from "react";
import PropTypes from "prop-types";
const CarouselCard = ({ image, name, role, body, style = {}, className = "" }) => {
  return (
    <div
      className={
        "testimonial-center-card bg-white p-4 rounded-2xl shadow-[0px_5px_10px_rgba(0,0,0,0.35)] text-center w-[80%] " +
        className
      }
      style={style}
    >
      <img src={image} className="w-[70px] mx-auto"></img>
      <h3 className="font-bold text-[1.07rem]">{name}</h3>
      <p className="text-gray-600 text-xs mb-3">{role}</p>
      <p className="testimonial-text md:hidden text-sm font-light italic">&quot;{body}&quot;</p>
    </div>
  );
};

CarouselCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

CarouselCard.defaultProps = {
  className: "",
  style: {},
};

export default CarouselCard;
