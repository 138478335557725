import React from "react";
import PropTypes from "prop-types";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "gatsby";

function SolutionLink({ solutionLink, isMEA }) {
  return (
    <Link key={solutionLink} className="text-[#0064bf] extrabold mb-6" to={solutionLink.link}>
      <div className="flex flex-row justify-between items-center text-black">
        {!isMEA && <img className="mr-2" src={solutionLink.image} />}
        <p className="text-sm text-[#0064BF] font-bold hover:text-white md:text-xs lg:text-sm xl:text-sm">
          {solutionLink.text}
          {isMEA && <BiChevronRight />}
        </p>
      </div>
    </Link>
  );
}
SolutionLink.propTypes = {
  solutionLink: PropTypes.shape({ link: PropTypes.string, image: PropTypes.string, text: PropTypes.string }).isRequired,
  isMEA: PropTypes.bool.isRequired,
};
export default SolutionLink;
