import React from "react";
import PropTypes from "prop-types";

function Badge({ firstText, secondText, thirdText, bgImg }) {
  return (
    <div className="bg-[#fff4d9]">
      <div className="m-auto max-w-[86%] lg:h-[46vh] flex flex-col-reverse sm:flex-row grid-cols-2 pt-8">
        <div className="lg:py-[20px] mb-[40px] xl:py-[68px] w-full ">
          <h1 className="text-[28px] md:text-[26px] xl:text-4xl xl:font-extrabold font-medium lg:text-[30px] text-[#0d1319] md:w-96 lg:w-full">
            {firstText}
          </h1>
          <div className="mt-[20px] text-[#0064bf] text-[26px] md:text-2xl font-medium xl:font-[32px] xl:font-bold">
            <h2>{secondText}</h2>
          </div>
        </div>
        <div className="lg:ml-[4%] pb-4 pt-4">
          <img className=" lg:w-10/12 lg:ml-8 md:w-11/12 md:ml-9" src={bgImg} />
        </div>
      </div>
      <div className="m-auto max-w-[86%] text-left text-[#0f1921] text-[20px] opacity-50 font-medium md:text-lg xl:text-lg xl:font-bold">
        <h1 className="pb-8 2xl:pb-24">{thirdText}</h1>
      </div>
    </div>
  );
}
Badge.propTypes = {
  bgImg: PropTypes.string,
  mobImg: PropTypes.string,
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  thirdText: PropTypes.string,
};
export default Badge;
