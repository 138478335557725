import { getStar } from "../utils";
export const PeopleSayCarouselLists = [
  { star: getStar(4), text: "“Great app for Performance management”" },
  { star: getStar(5), text: "“An intelligent Platform for managing employee performance.”" },
  { star: getStar(4), text: "“Useful system, the team was helpful and professional”" },
  { star: getStar(4), text: "“Excellent and customer friendly tool, loaded with great features”" },
  { star: getStar(5), text: "“Great product for workforce performance optimization!”" },
  { star: getStar(4), text: "“Friendly support and professional advices provided”" },
  { star: getStar(4), text: "“User friendly performance managaement system”" },
  { star: getStar(4), text: "“Great app for Performance management”" },
  { star: getStar(4), text: "“An intelligent Platform for managing employee performance.”" },
  { star: getStar(4), text: "“Useful system, the team was helpful and professional”" },
  { star: getStar(4), text: "“Excellent and customer friendly tool, loaded with great features”" },
  { star: getStar(4), text: "“Great product for workforce performance optimization!”" },
  { star: getStar(4), text: "“Friendly support and professional advices provided”" },
  { star: getStar(4), text: "“User friendly performance managaement system”" },
];
