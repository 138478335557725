import React from "react";
import PropTypes from "prop-types";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const TotalEcnomic = ({ title, subTitle, linkText, image, onClick }) => {
  return (
    <div className="flex sm:px-16 flex-col sm:flex-row md:px-0 lg:px-8 2xl:px-20 md:flex-col">
      <div>
        <GatsbyImage className="rounded-t-lg w-full" image={image} alt="image" />
      </div>
      <div className="text-[#ffff] p-8 text-left bg-[#0064bf] rounded-b-lg min-h-[28vh]">
        <span className=" text-sm font-bold">{title}</span>
        <hr className="w-full my-4"></hr>
        <p className="mb-6 leading-normal text-sm font-extrabold">{subTitle}</p>
        <div></div>
        <div onClick={onClick}>
          <Link className="text-[#fcdb03] text-sm font-extrabold">
            {linkText}
            <span>
              <BiChevronRight className="inline-block" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
TotalEcnomic.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default TotalEcnomic;
