import { Mukesh, Adrian, Ashandhi, Elson, Kornisiam, Mochtar, NasriMohamed } from "../images";
export const testimonialsList = [
  {
    image: Mukesh,
    name: "Mukesh Dhawan",
    role: "CEO | Zurich Takaful Malaysia Bhd Zurich Insurance Company Ltd",
    body: "“We’re beating the market quarter on quarter. entomo has taken us to the next level of engagement with substantial increase in the number of active agents and revenue.”",
  },
  {
    image: NasriMohamed,
    name: "Nasri Mohamed",
    role: "Ex. Vice President Telekom Malaysia",
    body: "“entomo enabled a 31% productivity increase for our 7000 plus front-liners with cost reduction of 14%, placing TM in the top quartile of telcos globally”",
  },
  {
    image: Kornisiam,
    name: "Kornsiam Nontarat",
    role: "Chief Agency Officer Generali",
    body: "“Agency Leaders and Agents are empowered with relevant insights that has taken both of their individual and team performance to a much higher level.  The dashboards give us a round the clock real-time knowledge of our performance, anytime, everywhere. Truly a next-generation performance management system.”",
  },
  {
    image: Adrian,
    name: "Adrian",
    role: "Activation & Technical Mastery PT Asuransi Jiwa Manulife Indonesia",
    body: "“entomo effectively helps us in seeing (Sales) district activities by providing updated production data during on-going discussions between leaders and agents. The CMP (Sales Process: Call, Meet, Present) makes it easy for us to monitor the Group’s activities.”",
  },
  {
    image: Mochtar,
    name: "Mochtar Suhadi",
    role: "Chief Organization & Development Officer Sinarmas Mining",
    body: "“The daily feed of key insights and intuitive dashboards at our fingertips has greatly improved our decision making process, our performance and made us more efficient.”",
  },
  {
    image: Ashandhi,
    name: "Ashanthi Fernando",
    role: "Head of Group Human Resources Dialog",
    body: "“entomo helps us manage and be appraised with the performance of our 3500+ employees in real-time. Teams are better equipped to track and streamline their performances. entomo has enhanced overall productivity and performance across the organization in a very short period of time”",
  },
  {
    image: Elson,
    name: "Elson Kho",
    role: "Executive Director Maxx Media",
    body: "“entomo has enabled substantial goal clarity and performance expectations for our people. Some of the features like Task management are particularly impactful in helping us track and measure delivery timelines. Simply put, there is clear progress in goal orientation, engagement, and people.”",
  },
];
