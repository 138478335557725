import React from "react";
import PropTypes from "prop-types";

function Award({ bgImg, mobImg, firstText, secondText, thirdText }) {
  return (
    <div className="relative md:py-20 bg-[#fff4d9] xl:pt-16 xl:pb-8 md:bg-[#fff4d9]">
      <img className="relative sm:block  hidden w-full" src={bgImg} alt="backgroundimage" />
      <img className="relative sm:hidden block" src={mobImg} alt="mobimage" />
      <div>
        <div className="absolute flex items-center flex-col top-80 pb-12 text-[28px] md:pt-8 md:text-[21px] leading-tight mx-5 md:mx-12 md:w-[46vw] md:top-[-14rem] xl:py-0 xl:top-[0rem] md:mt-64 xl:w-[68%] xl:pt-16 xl:mt-0 xl:items-start xl:mx-40">
          <div>
            <h2 className="xl:pb-8 text-[#0d1319] pb-4 text-xl md:text-base lg:text-2xl lg:font-normal xl:text-[28px]">
              {firstText}
            </h2>
            <h3 className="text-[#0064bf] xl:pb-8 pb-4  text-2xl md:text-sm md:font-extrabold lg:text-2xl xl:text-[1.6rem]">
              {secondText}
            </h3>
          </div>
          <div>
            <p className="text-[#0F1921] text-[18px] mb-[1.5rem] lowercase font-[Avnier Next] xl:pb-8 md:text-xs md:font-medium lg:text-lg">
              {thirdText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
Award.propTypes = {
  bgImg: PropTypes.string,
  mobImg: PropTypes.string,
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  thirdText: PropTypes.string,
};
export default Award;
