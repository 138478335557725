import React from "react";
import PropTypes from "prop-types";

export default function SpringAward({
  heading,
  textOne,
  textTwo,
  textThree,
  textFour,
  textFive,
  aspireImg,
  highperfomerImg,
  leaderImg,
  leadspringImg,
  usersImg,
}) {
  return (
    <div>
      <div className="flex flex-col bg-gradient-to-b from-neutral-11 to-neutral-12 md:bg[background: #353434] md:bg-[#353434] justify-center p-8 ">
        <div>
          <h2 className="text-center text-white text-[34px] md:text-[26px] xl:text-4xl font-extrabold xl:font-extrabold xl:pt-8">
            {heading}
          </h2>
        </div>
        <div className="flex justify-center mt-20 flex-col sm:flex-row">
          <div className="lg:w-1/6  lg:px-[15px] pb-12">
            <img className="w-full" src={aspireImg} alt="image" />
            <div className="text-center text-white text-2xl p-6 md:p-0 md:text-xs xl:text-[20px] lg:pt-[10px] opacity-100">
              {textOne}
            </div>
          </div>
          <div className="lg:w-1/6 lg:pt-24 lg:pb-[20px] lg:px-[15px] pb-12 xl:pt-32 md:pt-20 md:px-4">
            <img src={highperfomerImg} alt="image" />
            <div className="text-center text-white text-2xl p-6 md:p-0 md:text-xs  xl:text-[20px]lg:pt-[10px] opacity-100">
              {textTwo}
            </div>
          </div>
          <div className="lg:w-1/4 lg:pt-24 lg:pb-[20px] lg:px-[15px] pb-12 xl:pt-36 md:pt-36 md:px-4">
            <img src={leaderImg} alt="image" />
            <div className="text-center text-white text-2xl p-6 md:p-0 sm:text-center xl:text-[20px] sm:text-white md:text-xs lg:pt-[10px] opacity-100">
              {textThree}
            </div>
          </div>
          <div className="lg:w-1/6 lg:pb-[20px] lg:px-[15px] pb-12 xl:pt-32 md:pt-20 md:px-4">
            <img src={leadspringImg} alt="image" />
            <div className="text-center text-white text-2xl p-6 md:p-0 sm:text-center xl:text-[20px] sm:text-white md:text-xs lg:pt-[10px] opacity-100">
              {textFour}
            </div>
          </div>
          <div className="lg:w-1/6 lg:pb-[20px] lg:px-[15px] pb-12 ">
            <img className="w-full" src={usersImg} alt="image" />
            <div className="text-center text-white text-2xl p-6 md:p-0 sm:text-center xl:text-[20px] sm:text-white md:text-xs lg:pt-[10px] opacity-100">
              {textFive}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
SpringAward.propTypes = {
  heading: PropTypes.string.isRequired,
  textOne: PropTypes.string.isRequired,
  textTwo: PropTypes.shape({ backgroundColor: PropTypes.string, color: PropTypes.string, marginTop: PropTypes.number })
    .isRequired,
  textThree: PropTypes.string.isRequired,
  textFour: PropTypes.string.isRequired,
  textFive: PropTypes.string.isRequired,
  aspireImg: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    marginTop: PropTypes.number,
  }).isRequired,
  highperfomerImg: PropTypes.string.isRequired,
  leaderImg: PropTypes.string,
  leadspringImg: PropTypes.string.isRequired,
  usersImg: PropTypes.string.isRequired,
};
