import React from "react";
import PropTypes from "prop-types";

import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

import CarouselCard from "../components/CarouselCard";

const Testimonial = ({ testimonialsList }) => {
  console.log(testimonialsList);
  const settings = {
    className: "p-4",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 1000,
    arrows: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: <BsChevronRight color="#000000" />,
    prevArrow: <BsChevronLeft color="#000000" />,
  };

  return (
    // TODO : find a way to import the images and use as background
    <div className="testimonial bg-[url('../images/testimonial/bgMob.png')] md:bg-[url('../images/testimonial/bgDesk.svg')] bg-center bg-cover px-4 lg:px-32 xl:px-56 2xl:px-[20%]">
      <Slider {...settings}>
        {testimonialsList.map((testimonial) => (
          <CarouselCard key={testimonial.name} {...testimonial} />
        ))}
      </Slider>
    </div>
  );
};

Testimonial.propTypes = {
  testimonialsList: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
      body: PropTypes.string,
    })
  ),
};

export default Testimonial;
