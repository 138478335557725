import React, { useState } from "react";
import { VideoImg, PurposeImg, ModalImg, InsightImage, GrowthImg } from "../images/index";
import Button from "../components/Button";
import Modal from "../components/modal";

export default function EnterpriseOfTomorrow() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModalHandler = () => {
    setIsModalOpen(false);
    document.getElementsByTagName("body")[0].style.overflow = "scroll";
  };
  return (
    <div className="bg-[#0064bf]">
      <div className="md:grid md:grid-cols-2">
        <div className="flex items-center pt-[7rem] px-[3rem] xl:pl-32 md:pb-16 lg:pt-24 md:pt-0 xl:pt-[4rem] xl:items-unset">
          <div className="relative">
            <div
              onClick={() => {
                setIsModalOpen(true);
                document.getElementsByTagName("body")[0].style.overflow = "hidden";
              }}
            >
              <img className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src={VideoImg} />
              <img className="rounded-[10px]" src={ModalImg} />
            </div>
          </div>
          {isModalOpen ? <div onClick={closeModalHandler}></div> : null}

          <Modal
            isOpen={isModalOpen}
            closeModal={closeModalHandler}
            contentClassName="w-full md:w-[74%] xl:h-[71vh] 2xl:h-[61vh] xl:w-[63%] 2xl:w-[49%] h-[60vh]"
            btnstyle={{ fontsize: "12px" }}
            animate
          >
            <iframe
              className="w-full h-[88%]"
              src={isModalOpen ? "https://www.youtube.com/embed/Ot_C794fZ3A" : ""}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
            ></iframe>
          </Modal>
        </div>
        <div>
          <div className=" text-white py-[2rem] px-[3rem] lg:pt-16 xl:pl-32 items-center xl:pt-[4rem]">
            <div className="flex">
              <div className="flex text-[25px] items-center min-w-[41%] justify-between">
                <div className="pr-6">
                  <img className="pt-[1rem] pb-[2rem] xl:p-0" src={PurposeImg} />
                  <div className="min-h-[36px] md:min-h-0 xl:min-h-0 mt-[8px] mr-[8px] text-[18px] xl:m-0 ">
                    <p className="mt-[16px] mb-[20px]  ">Purpose</p>
                  </div>
                </div>
                <div className="pr-6">
                  <img className="pt-[1rem] pb-[2rem] xl:p-0" src={InsightImage} />
                  <div className="min-h-[36px] md:min-h-0 xl:min-h-0  mt-[8px] mr-[8px] text-[18px]">
                    <p className="mt-[16px] mb-[20px]">Insights</p>
                  </div>
                </div>
                <div className=" pr-6">
                  <img className="pt-[1rem] pb-[2rem] xl:p-0" src={GrowthImg} />
                  <div className="min-h-[36px] md:min-h-0 xl:min-h-0 mt-[8px] mr-[8px] text-[18px]">
                    <p className="mt-[16px] mb-[20px]">Growth</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-[1.5rem] mb-[20px]">
              <p>
                unravel the <br />
                enterprise of tomorrow
              </p>
            </div>
            <div
              onClick={() => {
                setIsModalOpen(true);
                document.getElementsByTagName("body")[0].style.overflow = "hidden";
              }}
            >
              <Button
                text="watch now"
                onClick={() => setIsModalOpen(true)}
                style={{ background: "#fcdb03", color: "#0f1921" }}
                {...(isModalOpen ? <div onClick={closeModalHandler}></div> : null)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
